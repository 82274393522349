<script setup lang='ts'>
import { checkPhone } from '@qctsw/utils'
import { LOAD_STATE } from '~/shared/types'
import { getVerificationCodeApi } from '@/server'

const props = withDefaults(defineProps<{
  phone: string
  uuid: string
  imgCode: string
  refresh?: Function
}>(), {})

const { verificationCodeText, verificationCodeStatus, verificationCodeStart } = useVerificationCode()
const loadVerificationCodeState = ref(LOAD_STATE.INIT)
const disabled = computed(() => {
  return !(checkPhone(props.phone) && props.imgCode)
    || verificationCodeStatus.value === 'change'
    || loadVerificationCodeState.value === LOAD_STATE.LOADING
})

async function getPhoneCode() {
  if (!checkPhone(props.phone))
    return useMessage.error('请输入正确的手机号')
  if (!props.uuid)
    return useMessage.error('请输入正确的图形验证码')

  const res = await useLoadState({
    state: loadVerificationCodeState,
    fn: () => getVerificationCodeApi({
      phone: props.phone,
      uuid: props.uuid,
      code: props.imgCode,
    }),
  })

  if (loadVerificationCodeState.value === LOAD_STATE.END || res?.code === 200) {
    useMessage.success('验证码已发送')
    verificationCodeStart()
  }
  if (!res && props.refresh)
    props.refresh()
}
</script>

<template>
  <button
    :disabled="disabled"
    :class="[disabled ? 'cursor-not-allowed bg-blue-3' : 'cursor-pointer']"
    @click="getPhoneCode"
  >
    <i v-if="loadVerificationCodeState === LOAD_STATE.LOADING" class="i-eos-icons:loading mr-1 vertical-icon" />
    <span v-else>{{ verificationCodeText }}</span>
  </button>
</template>

<style lang='scss' scoped>

</style>
